import React from "react";
import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"https://connect.facebook.net/de_DE/sdk.js#xfbml=1&appId=272376829538322&version=v2.0\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));"
          }}
        />
        <Header></Header>
        <div id="meganav">
          <div className="container">
            {" "}
            <a
              href="/"
              title="bebe Zartpflege®"
              target="_self"
              className="navbar-brand"
            >
              <em>
                bebe Zartpflege<sup>®</sup>
              </em>
            </a>
            <div className="close-meganav" />
            <div className="col-xs-12 meganav-top">
              {" "}
              <a
                href="/"
                target="_self"
                title="Start"
                className="navitem"
              >
                Start
              </a>{" "}
              <a
                href="/menu/kontakt/"
                target="_self"
                title="Kontakt"
                className="navitem"
              >
                Kontakt
              </a>{" "}
              <a
                href="/menu/faq/"
                target="_self"
                title="FAQ"
                className="navitem"
              >
                FAQ
              </a>{" "}
              <a
                href="/menu/impressum/"
                target="_self"
                title="Impressum"
                className="navitem"
              >
                Impressum
              </a>{" "}
              <a
                href="/menu/rechtliche-hinweise/"
                target="_self"
                title="Rechtliche Hinweise"
                className="navitem"
              >
                Rechtliche Hinweise
              </a>{" "}
              <a
                href="/menu/cookie-information/"
                target="_self"
                title="Cookies"
                className="navitem"
              >
                Cookies
              </a>{" "}
              <a
                href="/menu/allg-gewinnspielbedingungen/"
                target="_self"
                title="Allg. Gewinnspielbedingungen"
                className="navitem"
              >
                Allg. Gewinnspielbedingungen
              </a>{" "}
            </div>
            <div id="col-products" className="cat-links col-xs-12 col-sm-3">
              {" "}
              <a
                className="cat-thumb"
                href="/produkte/"
                target="_self"
              >
                {" "}
                <img
                  src="/fileadmin/images/_layout/meganav.thumb.produkte.png"
                  alt="Produkte"
                />{" "}
              </a>
              <ul>
                <li>
                  <a
                    href="/produkte/"
                    target="_self"
                    title="Produkte"
                  >
                    Produkte
                  </a>
                </li>
                <li>
                  <ul className="sub-navi">
                    <li>
                      <a
                        href="/produkte/#ZartcremeinderDose"
                        target="_self"
                      >
                        Zartcreme
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#schaumbad-und-dusche"
                        target="_self"
                      >
                        Schaumbad und Dusche
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#Glanzshampoo"
                        target="_self"
                      >
                        Glanzshampoo
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#Pflegelotion"
                        target="_self"
                      >
                        Pflegelotion
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#MildeHandseife"
                        target="_self"
                      >
                        Milde Handseife
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#shampoo"
                        target="_self"
                      >
                        Stärkendes Shampoo und Spülung
                      </a>
                    </li>
                    <li>
                    <a
                      href="/produkte/#Lippenpflegemit"
                      target="_self"
                    >  
                     Lippenpflege mit fruchtigem Kirschduft
                    </a>
                </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div
              id="col-unsere-maedels"
              className="cat-links col-xs-12 col-sm-3"
            >
              {" "}
              <a
                className="cat-thumb"
                href="/unsere-maedels/"
                target="_self"
              >
                {" "}
                <img
                  src="/fileadmin/images/_layout/meganav.thumb.unsere-maedels.png"
                  alt="Unsere Mädels"
                />{" "}
              </a>
              <ul>
                <li>
                  <a
                    href="/unsere-maedels/"
                    target="_self"
                    title="Unsere Mädels"
                  >
                    Unsere Mädels
                  </a>
                </li>
                <li>
                  <ul className="sub-navi">
                    <li>
                      <a
                        href="/unsere-maedels/#BibiBlubberblase"
                        target="_self"
                      >
                        Bibi Blubberblase
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#MiaMorgentau"
                        target="_self"
                      >
                        Mia Morgentau
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#PaulaPerlensanft"
                        target="_self"
                      >
                        Paula Perlensanft
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#ViviWallemaehne"
                        target="_self"
                      >
                        Vivi Wallemähne
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#SophieSonnenschein"
                        target="_self"
                      >
                        Sophie Sonnenschein
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#Malbuch"
                        target="_self"
                      >
                        Mal- &amp; Rätselbuch
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div id="col-marken" className="cat-links col-xs-12 col-sm-3">
              {" "}
              <a
                className="cat-thumb"
                href="/markenhistorie/"
                target="_self"
              >
                {" "}
                <img
                  src="/fileadmin/images/_layout/meganav.thumb.markenhistorie.png"
                  alt="Markenhistorie"
                />{" "}
              </a>
              <ul>
                <li>
                  <a
                    href="/markenhistorie/"
                    target="_self"
                    title="Markenhistorie"
                  >
                    Markenhistorie
                  </a>
                </li>
                <li>
                  <ul className="sub-navi">
                    <li>
                      <a href="/markenhistorie/#60er" target="_self">
                        60er
                      </a>
                    </li>
                    <li>
                      <a href="/markenhistorie/#70er" target="_self">
                        70er
                      </a>
                    </li>
                    <li>
                      <a href="/markenhistorie/#80er" target="_self">
                        80er
                      </a>
                    </li>
                    <li>
                      <a href="/markenhistorie/#90er" target="_self">
                        90er
                      </a>
                    </li>
                    <li>
                      <a
                        href="/markenhistorie/#2000er"
                        target="_self"
                      >
                        2000er
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div
              id="col-limited-editions"
              className="cat-links col-xs-12 col-sm-3"
            >
              {" "}
              <a
                className="cat-thumb"
                href="/limited-editions/"
                target="_self"
              >
                {" "}
                <img
                  src="/fileadmin/images/_layout/meganav.thumb.limited_editions.png"
                  alt="Limited Editions"
                />{" "}
              </a>
              <ul>
                <li>
                  <a
                    href="/limited-editions/"
                    target="_self"
                    title="Limited Editions"
                  >
                    Limited Editions
                  </a>
                </li>
                <li>
                  <ul className="sub-navi">
                    <li>
                      <a
                        href="/limited-editions/#OnlineGame"
                        target="_self"
                      >
                        Online Game
                      </a>
                    </li>
                    <li>
                      <a
                        href="/limited-editions/#LimitedEditions"
                        target="_self"
                      >
                        Limited Editions
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 meganav-mobile-bottom">
              {" "}
              <a
                className="navitem"
                title="Start"
                target="_self"
                href="/"
              >
                Start
              </a>{" "}
              <a
                className="navitem"
                title="Kontakt"
                target="_self"
                href="/menu/kontakt/"
              >
                Kontakt
              </a>{" "}
              Newsletter{" "}
            </div>
          </div>
        </div>
        <div id="stage">
          <div className="container"> </div>
        </div>
        <div className="scroll-bottom-arrow">
          <div className="arrow-animated" />
          <div className="arrow-hint" />
        </div>
        <div id="content">
          <div className="container">
            <h1>Allg. Gewinnspielbedingungen</h1>
            <p>
              Diese Allgemeinen Gewinnspielbedingungen regeln die Teilnahme an
              allen Gewinnspielen auf der Seite{" "}
              <a href="/" className="internal-link">
                www.bebe-zartpflege.de
              </a>{" "}
              und deren Durchführung, sofern nicht explizit auf gesonderte
              Teilnahmebedingungen verwiesen wird. Veranstalterin ist die
              Johnson &amp; Johnson GmbH, Johnson &amp; Johnson Platz 2, 41470
              Neuss (nachfolgend bezeichnet als »Veranstalter«). Mit der
              Teilnahme am jeweiligen Gewinnspiel akzeptieren die Teilnehmer die
              nachfolgenden Bestimmungen.
            </p>
            <p>
              <strong>1. Teilnahmeberechtigung</strong>{" "}
            </p>
            <p>
              Teilnahmeberechtigt sind – sofern nicht anders im jeweiligen
              Gewinnspiel beschrieben – natürliche Personen, die ihren Wohnsitz
              in Deutschland, Österreich oder der Schweiz haben und mindestens
              18 Jahre sind. Mitarbeiter des Veranstalters und mit ihm
              verbundener Unternehmen sowie Mitarbeiter von
              Kooperationspartnern, die mit der Erstellung oder Abwicklung des
              Gewinnspiels beschäftigt sind oder waren, sind ausgeschlossen.
              Mehrfacheinsendungen sowie die Teilnahme über ein automatisiertes
              Massenverfahren oder Gewinnspielvereinigungen sind unzulässig.
            </p>
            <p>
              <strong>2. Einzelheiten zum Gewinnspiel</strong>{" "}
            </p>
            <p>
              Der genaue Teilnahmezeitraum, die Gewinnspielfrage bzw. die Art
              der Teilnahme- und Gewinnmöglichkeit sowie ggf. die Einzelheiten
              zum Gewinn werden auf der jeweiligen Gewinnspielseite genau
              beschrieben.
            </p>
            <p>
              <strong>3. Gewinnabwicklung</strong>{" "}
            </p>
            <p>
              Der Gewinn wird entweder an die vom Teilnehmer angegebene
              Postadresse geschickt oder die Gewinner werden nach Beendigung des
              Gewinnspiels über die im Gewinnspiel angegebene E-Mail-Adresse
              (bei Gewinnspielen über fremde Seiten wie Facebook: durch einen
              Post auf der entsprechenden Seite) über den Gewinn, die
              Gewinnabwicklung und eventuell notwendige nächste Schritte (z.B.
              die Übermittlung der vollständigen Postadresse oder Rücksendung
              etwaiger Unterlagen) informiert. Wird ein Teilnehmer nicht
              erreicht oder befolgt er die nächsten Schritte nicht innerhalb der
              ihm mitgeteilten Zeit, kann der Veranstalter einen neuen Gewinner
              ermitteln. Der Anspruch des ursprünglichen Gewinners entfällt.
            </p>
            <p>
              <strong>4. Gewinn</strong>{" "}
            </p>
            <p>
              Der im Rahmen des Gewinnspiels als Gewinn präsentierte Gegenstand
              kann ggf. von dem gewonnenen Gegenstand abweichen (z.B.
              hinsichtlich Modell, Farbe o. ä.).
            </p>
            <p>
              Eine Barauszahlung des Gewinns (sofern es sich nicht um einen
              Geldgewinn handelt) oder seine Übertragung auf andere Personen ist
              nicht möglich.
            </p>
            <p>
              Gewinne werden nur an eine Adresse in den für das Gewinnspiel
              zugelassenen Wohnsitzländern geliefert. Leistungsort bleibt trotz
              Übernahme der Versendungskosten der Sitz des Veranstalters.
            </p>
            <p>
              Bei Reisegewinnen erfolgt die Abwicklung zwischen dem Gewinner und
              dem Veranstalter bzw. einer von diesem beauftragten Agentur oder
              einem Reiseveranstalter. Ein Anspruch auf einen bestimmten
              Reisetermin besteht nicht. Wird die Reise aus Gründen, die der
              Veranstalter nicht zu vertreten hat, nicht zu dem im Gewinnspiel
              vorgegebenen Termin oder Zeitraum durchgeführt, besteht kein
              Anspruch mehr auf den Gewinn. Mit Bestätigung der Reise unterliegt
              der Gewinner den Reisebedingungen des Reiseveranstalters. Die An-
              und Abreise zum Ausgangspunkt der Reise (Flughafen, Bahnhof etc.)
              erfolgt auf Kosten des Gewinners, soweit nichts anderes im
              Gewinnspiel angegeben oder ausdrücklich vereinbart wurde. Das
              Gleiche gilt auch für sämtliche privaten Kosten, die während der
              Reise entstehen (Minibar, Telefon etc.).
            </p>
            <p>
              Der Anspruch des ursprünglichen Gewinners entfällt, wenn die
              Übermittlung des Gewinns aus Gründen die in seiner Sphäre liegen,
              nicht innerhalb von einem Monat nach der ersten
              Gewinnbenachrichtigung erfolgt.
            </p>
            <p>
              <strong>
                5. Nutzungsrechte, Freistellung und Verantwortlichkeit
              </strong>{" "}
            </p>
            <p>
              Sofern im Rahmen des Gewinnspiels kreative Beiträge eingereicht
              werden (z.B. Fotos, Videos oder Geschichten) gilt Folgendes:
            </p>
            <p>
              a) Der Veranstalter ist nicht verpflichtet, die von den
              Teilnehmern bereitgestellten Inhalte (zum Beispiel Bilder) auf
              potenzielle Verletzungen der Rechte Dritter zu überprüfen. Der
              Veranstalter ist jedoch berechtigt, Inhalte abzulehnen, wenn sie
              nach seiner sachgerechten Einschätzung rechtswidrig sind oder
              gegen die guten Sitten verstoßen. Mit dem Hochladen von Inhalten,
              insbesondere Bildern, erklären die Teilnehmer, dass diese frei von
              Rechten Dritter sind, also dass die Inhalte entweder von ihnen
              stammen oder sie die Einwilligung der Urheber sowie der auf
              Bildern und Videos abgebildeten Personen eingeholt haben und diese
              mindestens 16 Jahre alt sind. Die Teilnehmer stellen den
              Veranstalter von allen Ansprüchen Dritter frei, die aufgrund der
              bereitgestellten Inhalte entstanden sind. Sie erklären sich
              bereit, den Veranstalter in jeder zumutbaren Form bei der Abwehr
              dieser Ansprüche zu unterstützen.
            </p>
            <p>
              b) Die Teilnehmer sind damit einverstanden, dass ihre Einsendungen
              im Zusammenhang mit dem Gewinnspiel und der Auslosung, Abwicklung
              und Präsentation der Teilnehmerbeiträge vom Veranstalter in
              Online- wie Offlinemedien (zum Beispiel im Printbereich) und durch
              alle anderen bekannten Nutzungsarten genutzt, verbreitet sowie auf
              sonstige Weise Dritten öffentlich zugänglich gemacht werden. Für
              diese Zwecke ist es dem Veranstalter außerdem erlaubt, die
              Einsendungen zu bearbeiten und – falls es erforderlich ist –
              Dritten Nutzungsrechte an ihnen einzuräumen. Von der Einräumung
              der Nutzungsrechte ist die Möglichkeit umfasst, das Bildmaterial
              zum Abruf durch Dritte im Onlinebereich zur Verfügung zu stellen
              und die Bilder zu archivieren. Die Rechteeinräumung erfolgt
              unentgeltlich sowie ohne räumliche, inhaltliche oder zeitliche
              Beschränkung.
            </p>
            <p>
              c) Der Teilnehmer verzichtet auf sein etwaiges Recht auf
              Urhebernennung aus § 13 UrhG sowie auf die vollständige Nennung
              seines Namens im Zusammenhang mit seinen Einsendungen.
            </p>
            <p>
              <strong>6. Verfügbarkeit und Funktion des Gewinnspiels</strong>{" "}
            </p>
            <p>
              Die Verfügbarkeit und Funktion des Gewinnspiels können nicht
              gewährleistet werden. Es kann aufgrund von äußeren Umständen oder
              Zwängen beendet oder entfernt werden, ohne dass hieraus Ansprüche
              der Teilnehmer gegenüber dem Veranstalter entstehen.
              <br /> Hierzu gehören u.a. technische Probleme, Regeländerungen
              oder Entscheidungen durch Plattformen wie Facebook, falls das
              Gewinnspiel auf oder in Zusammenhang mit ihnen veranstaltet wird.
            </p>
            <p>
              <strong>7. Haftung</strong>{" "}
            </p>
            <p>
              Für eine Haftung des Veranstalters auf Schadensersatz gelten
              unbeschadet der sonstigen gesetzlichen Anspruchsvoraussetzungen
              gemäß dieser Ziffer folgende Haftungsausschlüsse und
              Haftungsbegrenzungen:
              <br /> Der Veranstalter haftet unbeschränkt, soweit die
              Schadensursache auf Vorsatz oder grober Fahrlässigkeit beruht.
              Ferner haftet der Veranstalter für die leicht fahrlässige
              Verletzung von wesentlichen Pflichten, deren Verletzung die
              Erreichung des Vertragszwecks gefährdet, oder für die Verletzung
              von Pflichten, deren Erfüllung die ordnungsgemäße Durchführung des
              Gewinnspiels überhaupt erst ermöglichen und auf deren Einhaltung
              die Vertragspartner regelmäßig vertrauen. In diesem Fall haftet
              der Veranstalter jedoch nur für den vorhersehbaren,
              vertragstypischen Schaden. Der Veranstalter haftet nicht für die
              leicht fahrlässige Verletzung anderer als der in den vorstehenden
              Sätzen genannten Pflichten.
              <br /> Die vorstehenden Haftungsbeschränkungen gelten nicht bei
              Verletzung von Leben, Körper und Gesundheit, für einen Mangel nach
              Übernahme von Beschaffenheitsgarantien für die Beschaffenheit
              eines Produktes und bei arglistig verschwiegenen Mängeln. Die
              Haftung nach dem Produkthaftungsgesetz bleibt unberührt.
              <br /> Soweit die Haftung des Veranstalters ausgeschlossen oder
              beschränkt ist, gilt dies auch für die persönliche Haftung von
              Arbeitnehmern, Vertretern und Erfüllungsgehilfen des
              Veranstalters.
            </p>
            <p>
              <strong>
                7.a Zusätzlicher Haftungsausschluss für Reisemängel
              </strong>{" "}
            </p>
            <p>
              Handelt es sich bei dem Gewinn um eine Reise, bei der der
              Veranstalter ausnahmsweise als Reiseveranstalter anzusehen ist,
              und hat der Gewinnspielteilnehmer eine echte Gegenleistung
              erbracht (z.B. Einsendung von Texten, Fotos, Songs oder Videos) so
              gilt zusätzlich zu Ziffer 7 folgendes:
            </p>
            <p>
              Die vertragliche Haftung des Veranstalters für Schäden, die keine
              Körperschäden sind, wird auf den dreifachen Reisepreis beschränkt,
            </p>
            <p>
              - soweit ein Schaden der Teilnehmer weder vorsätzlich noch grob
              fahrlässig durch den Veranstalter herbeigeführt wird oder{" "}
            </p>
            <p>
              - soweit der Veranstalter für einen dem Reisenden entstehenden
              Schaden allein wegen eines Verschuldens eines Leistungsträgers
              (z.B. Fluggesellschaft, Hotel) verantwortlich ist.{" "}
            </p>
            <p>
              Der Veranstalter haftet nicht für Leistungsstörungen, Personen-
              und Sachschäden im Zusammenhang mit Leistungen, die als
              Fremdleistungen lediglich vermittelt werden, wenn diese Leistungen
              für den Reisenden erkennbar nicht Bestandteil der Reiseleistungen
              des Veranstalters sind. Dies gilt nicht, wenn für einen Schaden
              des Reisenden die Verletzung von Hinweis-, Aufklärungs- und
              Organisationspflichten des Veranstalters ursächlich geworden ist.
            </p>
            <p>
              Lediglich klarstellend weisen wir darauf hin, dass eine Haftung
              des Veranstalters für Reisemängel ohnehin ausgeschlossen ist,
              sofern dieser nicht als Reiseveranstalter auftritt (also keine
              Gesamtheit von Reiseleistungen in eigener Verantwortung
              zusammenstellt, sondern lediglich einen Vertrag mit einem dritten
              Reiseveranstalter vermittelt, oder vom Gewinner keine nennenswerte
              Leistung erbracht werden musste, um am Gewinnspiel teilnehmen zu
              können.
            </p>
            <p>
              <strong>8. Datenschutzhinweise</strong>{" "}
            </p>
            <p>
              Der Veranstalter erhebt und nutzt die personenbezogenen Daten des
              Teilnehmers nur, soweit der Teilnehmer eingewilligt hat oder dies
              anderweitig gesetzlich erlaubt ist. Das Weitere ist beim
              Teilnahmeformular erläutert. Es ist zudem die{" "}
              <a
                href="/datenschutzbedingungen/"
                className="internal-link"
              >
                Datenschutzerklärung
              </a>{" "}
              des Veranstalters zu beachten.
            </p>
            <p>
              <strong>
                9. Hinweise und Bedingungen von dritten Plattformen (z.B.
                Facebook)
              </strong>{" "}
            </p>
            <p>
              Sofern das Gewinnspiel vom Veranstalter über eine dritte Plattform
              (z.B. Facebook, Instagram, Youtube oder Pinterest) angeboten wird,
              gilt Folgendes:
            </p>
            <p>
              a) Neben diesen Teilnahmebedingungen wird das Verhältnis zwischen
              dem Veranstalter, dem Teilnehmer und der dritten Plattform durch
              die Teilnahmebedingungen und die Datenschutzregeln des
              Plattformbetreibers (siehe z.B.{" "}
              <a href="https://www.facebook.com/terms.php" target="_blank">
                https://www.facebook.com/terms.php
              </a>{" "}
              bzw.{" "}
              <a href="https://www.facebook.com/privacy" target="_blank">
                https://www.facebook.com/privacy
              </a>{" "}
              bestimmt.
            </p>
            <p>
              b) Die Teilnehmer können gegenüber der dritten Plattform (z.B.
              Facebook) keine Ansprüche geltend machen, die im Zusammenhang mit
              der Nutzung der Gewinnspielapplikation oder Teilnahme am
              Gewinnspiel entstehen.
            </p>
            <p>
              c) Die Teilnehmer erkennen an, dass sowohl die
              Gewinnspielapplikation als auch das Gewinnspiel in keiner Weise
              von der dritten Plattform (z.B. Facebook) gesponsert, unterstützt
              oder organisiert werden bzw. in keiner Verbindung zu der dritten
              Plattform (z.B. Facebook) stehen.
            </p>
            <p>
              d) Alle Informationen und Daten, die durch die Nutzung der
              Gewinnspielapplikation oder beim Gewinnspiel durch die Teilnehmer
              mitgeteilt oder von diesen erhoben werden, werden nur dem
              Veranstalter und nicht der dritten Plattform (z.B. Facebook)
              bereitgestellt.
            </p>
            <p>
              e) Sämtliche Anfragen und Hinweise bezüglich des Gewinnspiels sind
              an den Veranstalter und nicht an die dritten Plattform (z.B.
              Facebook) zu richten.
            </p>
            <p>
              <strong>10. Sonstige rechtliche Hinweise</strong>{" "}
            </p>
            <p>
              Der Veranstalter behält sich das Recht vor, Personen vom
              Gewinnspiel auszuschließen. Ausgeschlossen werden insbesondere
              Personen, die sich unerlaubter Hilfsmittel bedienen oder sich
              anderweitig durch Manipulation Vorteile verschaffen. Der
              Veranstalter kann einen solchen Ausschluss auch nachträglich
              aussprechen, Gewinne aberkennen oder diese zurückfordern. In der
              Beurteilung ist der Veranstalter frei.
            </p>
            <p>
              Sollten einzelne Bestimmungen in diesen Teilnahmebedingungen
              unwirksam sein, so berührt diese Unwirksamkeit nicht die
              Wirksamkeit der Teilnahmebedingungen im Übrigen.
            </p>
            <p>
              Es gilt das Recht der Bundesrepublik Deutschland (ggf. in
              Kombination mit unabdingbaren Regelungen des schweizerischen oder
              österreichischen Rechts). Der Rechtsweg ist ausgeschlossen.
            </p>
          </div>
        </div>
        <Footer></Footer>
        <script
          src="/fileadmin/js/vendor/jquery6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/detectmobilebrowser6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/bootstrap.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/jquery.ba-throttle-debounce.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/skrollr.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/isMobile.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/jquery.scrollTo.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/script71c5.js?1527068951"
          type="text/javascript"
        />
      </div>
    );
  }
}

export default Page;
